export const API_URL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://staging-firebird.text-em-all.com';

const NO_CONTENT_RESPONSE = 204;

export const fetchApiData = (method, url, body) => {
  const options = {};

  if (method !== 'GET') {
    options.body = JSON.stringify(body);
  }

  return fetch(`${API_URL}${url}`, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...options,
  }).then((response) => {
    if (response.ok && response.status === NO_CONTENT_RESPONSE) {
      // Avoid attempting to get JSON when there's no content
      return response;
    }

    return response.json();
  });
};

export const apiGet = (url, invoiceId, paymentCode) => {
  return fetchApiData(
    'GET',
    `${url}?invoiceId=${invoiceId}&paymentCode=${paymentCode}`
  );
};

export const apiPut = (url, body) => {
  return fetchApiData('PUT', url, body);
};

export const apiPost = (url, body) => {
  return fetchApiData('POST', url, body);
};

// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ButtonBase from '@mui/material/ButtonBase';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { selectPaymentMethod } from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import { updatePaymentMethod } from '../../state/actions/payment-actions';
import paymentMethodTypes from '../../constants/payment-method-constants';

const options = [
  { value: paymentMethodTypes.CARD, label: 'Card', Icon: CreditCardIcon },
  {
    value: paymentMethodTypes.BANK_ACCOUNT,
    label: 'US bank account',
    Icon: AccountBalanceIcon,
  },
];

const PaymentMethodOption = styled(ButtonBase)(({ theme, selected }) => ({
  width: 100,
  height: 80,
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  marginLeft: 2,
  marginRight: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.paper,
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  boxShadow: selected
    ? `0px 1px 2px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 2px ${theme.palette.primary.main}`
    : theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease',
  padding: theme.spacing(1.5),

  '&:focus': {
    boxShadow: `0 0 4px 2px ${theme.palette.primary.main}`,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  // MUI provides a -11 left margin, which causes alignment problems here
  marginLeft: 0,
  paddingTop: theme.spacing(1),
}));

const PaymentMethodControl = () => {
  const dispatch = useDispatch();
  const paymentMethod = useSelector(selectPaymentMethod);

  const handleUpdatePaymentMethod = (value) => {
    // call action paymentType: event.target.value
    dispatch(updatePaymentMethod(value));
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        name="paymentMethod"
        onChange={handleUpdatePaymentMethod}
        row
        value={paymentMethod}
      >
        {options.map((option) => (
          <StyledFormControlLabel
            control={
              <PaymentMethodOption
                {...setTestId(`payment-method-${option.value}`)}
                focusRipple
                key={option.value}
                onClick={() => handleUpdatePaymentMethod(option.value)}
                selected={paymentMethod === option.value}
              >
                <option.Icon fontSize="small" />
                <Typography fontSize={14} fontWeight="bold" lineHeight="16px">
                  {option.label}
                </Typography>
              </PaymentMethodOption>
            }
            key={`payment-method-${option.value}`}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PaymentMethodControl;

// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { selectStripeCustomerBankAccount } from '../../state/selectors/selectors';
import ExistingPaymentMethod from './existing-payment-method';
import paymentMethodTypes from '../../constants/payment-method-constants';

const ExistingBankAccount = () => {
  const bankAccount = useSelector(selectStripeCustomerBankAccount);
  const formattedDisplay = `•••• ${bankAccount?.AccountNumberLast4Digits}`;

  return (
    <ExistingPaymentMethod
      number={formattedDisplay}
      type={paymentMethodTypes.BANK_ACCOUNT}
    />
  );
};

export default ExistingBankAccount;

// External Dependencies
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Box from '@mui/material/Box';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { setTestId } from '../../util/testUtils';
import { updatePaymentType } from '../../state/actions/payment-actions';
import paymentMethodTypes from '../../constants/payment-method-constants';
import paymentTypes from '../../constants/payment-type-constants';

const StyledRoot = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.secondary,
}));

const ExistingPaymentMethod = ({ type, number }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    handleClose();
    dispatch(updatePaymentType(paymentTypes.NEW));
  };

  const Icon =
    type === paymentMethodTypes.CARD ? CreditCardIcon : AccountBalanceIcon;
  const label =
    type === paymentMethodTypes.CARD ? 'Saved Card' : 'Saved Bank Account';

  return (
    <StyledRoot>
      <Icon fontSize="large" />
      <Box ml={2}>
        <Typography color="textPrimary" fontWeight="bold">
          {label}
        </Typography>
        <Typography color="textSecondary">{number}</Typography>
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end">
        <IconButton
          aria-label="more"
          onClick={handleClick}
          {...setTestId('payment-method-more-options')}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
          <MenuItem
            {...setTestId('payment-method-menu-item-change')}
            onClick={handleChange}
          >
            Change
          </MenuItem>
        </Menu>
      </Box>
    </StyledRoot>
  );
};

export default ExistingPaymentMethod;

// External Dependencies
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { formatPaymentAmount } from '../../util/currency';
import {
  selectInvoiceBalanceDue,
  selectPaymentAmount,
  selectPaymentMethod,
} from '../../state/selectors/selectors';
import ConfirmAchPaymentButton from './confirm-ach-payment-button';
import ConfirmCardPaymentButton from './confirm-card-payment-button';
import paymentMethodTypes from '../../constants/payment-method-constants';

const ConfirmPaymentDialog = ({ isDialogOpen, setIsDialogOpen }) => {
  const paymentMethod = useSelector(selectPaymentMethod);
  const paymentAmount = useSelector(selectPaymentAmount);
  const balanceDue = useSelector(selectInvoiceBalanceDue);

  const isCard = paymentMethod === paymentMethodTypes.CARD;

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const formattedPaymentAmount = formatPaymentAmount(paymentAmount, balanceDue);

  const paymentMethodDisplay = isCard ? 'Card' : 'Bank Account';

  const ButtonElement = isCard
    ? ConfirmCardPaymentButton
    : ConfirmAchPaymentButton;

  return (
    <Dialog onClose={handleClose} open={isDialogOpen}>
      <DialogTitle>Confirm Payment</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          You&apos;re about to make a payment.
          <Typography mt={1}>Amount: {formattedPaymentAmount}</Typography>
          <Typography>Payment Method: {paymentMethodDisplay}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <ButtonElement
          onSubmit={handleClose}
          paymentAmount={formattedPaymentAmount}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPaymentDialog;

// Internal Dependencies
import { getInvoiceById } from './invoice-actions';
import { hasErrors, validate } from '../../util/validation';
import { navigateTo } from '../../util/navigation';
import { selectSignIn } from '../selectors/selectors';
import actionTypes from '../action-types';

const validationRules = {
  invoiceId: {
    displayName: 'Invoice ID',
    required: true,
    requiredMessage: 'Enter an Invoice ID',
    maxLength: 8,
    number: true,
  },
  paymentCode: {
    displayName: 'Payment Code',
    required: true,
    maxLength: 10,
    hex: true,
  },
};

export const update = (changes) => (dispatch) => {
  const validatedForm = validate(changes, validationRules);
  dispatch({ type: actionTypes.UPDATE_SIGN_IN, validatedForm });
  dispatch({ type: actionTypes.RESET_STEP_SIGN_IN });
};

export const signInSubmit =
  (invoiceId, paymentCode) => (dispatch, getState) => {
    const signIn = selectSignIn(getState());
    const validatedForm = validate(signIn, validationRules);

    if (hasErrors(validatedForm)) {
      dispatch({ type: actionTypes.UPDATE_SIGN_IN, validatedForm });
      return Promise.resolve();
    }
    return dispatch(getInvoiceById(invoiceId, paymentCode));
  };

export const signInSuccess = () => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_SIGN_IN,
    validatedForm: { isSignInComplete: true },
  });
};

export const resetApp = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_APP,
  });
};

export const navigateSignin = () => (dispatch) => {
  dispatch(resetApp());

  navigateTo('/');
};

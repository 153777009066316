import actionTypes from '../action-types';

const initialState = {
  isGetting: false,
  isSaving: false,
  isEditing: false,
  error: null,
  poError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return initialState;
    case actionTypes.GET_INVOICE_REQUEST:
      return {
        ...state,
        error: null,
        isGetting: true,
      };
    case actionTypes.GET_INVOICE_SUCCESS:
      return {
        ...state,
        ...action.invoice,
        isGetting: false,
      };
    case actionTypes.GET_INVOICE_ERROR:
      return {
        ...state,
        error: action.error,
        isGetting: false,
      };
    case actionTypes.INVOICE_OPEN_EDIT:
      return {
        ...state,
        isEditing: true,
      };
    case actionTypes.INVOICE_CLOSE_EDIT:
      return {
        ...state,
        isEditing: false,
      };
    case actionTypes.POST_PO_REQUEST:
      return {
        ...state,
        isSaving: true,
        poError: null,
      };
    case actionTypes.POST_PO_SUCCESS:
      return {
        ...state,
        PONumber: action.poNumber,
        isSaving: false,
        poError: null,
      };
    case actionTypes.POST_PO_ERROR:
      return {
        ...state,
        poError: action.error,
        isSaving: false,
      };
    case actionTypes.POST_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          MaskedNumber: action.MaskedNumber,
          AccountCreditId: action.AccountCreditId,
        },
      };
    default:
      return state;
  }
};

// External Dependencies
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Internal Dependencies
import { getInvoiceAchReceipt } from '../../state/actions/invoice-actions';
import { getQueryParam } from '../../util/queryParamUtils';
import { selectInvoiceId } from '../../state/selectors/selectors';
import PaymentSuccess from '../PaymentSuccess';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AchSuccess = () => {
  const dispatch = useDispatch();
  const isInvoiceLoaded = useSelector(selectInvoiceId);

  const invoiceId = getQueryParam('invoiceid');
  const paymentCode = getQueryParam('paymentcode');
  const paymentIntentSecret = getQueryParam('payment_intent_client_secret');

  useEffect(() => {
    if (!isInvoiceLoaded) {
      stripePromise
        .then((stripe) => stripe.retrievePaymentIntent(paymentIntentSecret))
        .then((response) => {
          if (response.error) {
            console.error(response.error.message);
          } else {
            const { paymentIntent } = response;
            dispatch(
              getInvoiceAchReceipt(invoiceId, paymentCode, paymentIntent)
            );
          }
        });
    }
  }, [dispatch, invoiceId, isInvoiceLoaded, paymentCode, paymentIntentSecret]);

  if (!isInvoiceLoaded) {
    return null;
  }

  return <PaymentSuccess />;
};

export default AchSuccess;

const actionTypes = {
  RESET_APP: 'RESET_APP',

  // Stepper
  UPDATE_STEP: 'UPDATE_STEP',
  RESET_STEP_SIGN_IN: 'RESET_STEP_SIGN_IN',
  RESET_STEP_INVOICE: 'RESET_STEP_INVOICE',
  RESET_STEP_PAYMENT: 'RESET_STEP_PAYMENT',

  // Sign In
  UPDATE_SIGN_IN: 'UPDATE_SIGN_IN',

  // Invoice
  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_ERROR: 'GET_INVOICE_ERROR',
  POST_PO_REQUEST: 'POST_PO_REQUEST',
  POST_PO_SUCCESS: 'POST_PO_SUCCESS',
  POST_PO_ERROR: 'POST_PO_ERROR',
  INVOICE_OPEN_EDIT: 'INVOICE_OPEN_EDIT',
  INVOICE_CLOSE_EDIT: 'INVOICE_CLOSE_EDIT',

  // Payment
  UPDATE_PAYMENT_TYPE: 'UPDATE_PAYMENT_TYPE',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_AMOUNT: 'UPDATE_PAYMENT_AMOUNT',
  UPDATE_PAYMENT_AMOUNT_TYPE: 'UPDATE_PAYMENT_AMOUNT_TYPE',
  POST_CREDIT_CARD_REQUEST: 'POST_CREDIT_CARD_REQUEST',
  POST_CREDIT_CARD_SUCCESS: 'POST_CREDIT_CARD_SUCCESS',
  POST_CREDIT_CARD_ERROR: 'POST_CREDIT_CARD_ERROR',

  POST_INVOICE_PAYMENT_REQUEST: 'POST_INVOICE_PAYMENT_REQUEST',
  POST_INVOICE_PAYMENT_SUCCESS: 'POST_INVOICE_PAYMENT_SUCCESS',
  POST_INVOICE_PAYMENT_ERROR: 'POST_INVOICE_PAYMENT_ERROR',
  CLEAR_PAYMENT_ERROR: 'CLEAR_PAYMENT_ERROR',

  // Payment Method
  UPDATE_PAYMENT_FORM: 'UPDATE_PAYMENT_FORM',

  // Stripe
  POST_STRIPE_PAYMENT_INTENT_SUCCESS: 'POST_STRIPE_PAYMENT_INTENT_SUCCESS',
  GET_STRIPE_CUSTOMER_SUCCESS: 'GET_STRIPE_CUSTOMER_SUCCESS',
  PUT_PAYMENT_INTENT_AMOUNT_SUCCESS: 'PUT_PAYMENT_INTENT_AMOUNT_SUCCESS',
};

export default actionTypes;

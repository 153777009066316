// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import React, { useEffect } from 'react';

// Internal Dependencies
import { navigateSignin } from '../state/actions/signin-actions';
import {
  selectIsInvoicePaid,
  selectIsInvoicePayable,
  selectIsSignInComplete,
} from '../state/selectors/selectors';
import InvoiceSummary from './invoice/Invoice-summary';
import PaymentForm from './PaymentForm';
import StripeProvider from './stripe/stripe-provider';

const transitionDelay = {
  transitionDelay: '150ms',
};

const Checkout = () => {
  const dispatch = useDispatch();
  const isSignInComplete = useSelector(selectIsSignInComplete);
  const isPaid = useSelector(selectIsInvoicePaid);
  const isPayable = useSelector(selectIsInvoicePayable);

  useEffect(() => {
    if (!isSignInComplete || isPaid) {
      dispatch(navigateSignin());
    }

    // Do not add isPaid as a dependency, as we only want to redirect on initial load.
    // If we add isPaid as a dependency, the component will redirect instead of showing
    // the success page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignInComplete, dispatch]);

  if (!isSignInComplete) {
    // Do not render until user authenticates
    return null;
  }

  return (
    <Fade in style={transitionDelay}>
      <div>
        <StripeProvider>
          {isPayable && <PaymentForm />}
          <InvoiceSummary />
        </StripeProvider>
      </div>
    </Fade>
  );
};

export default Checkout;

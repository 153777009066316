import { hasErrors } from '../../util/validation';
import status from '../../constants/invoice-status';

/** Sign In */
export const selectSignIn = (state) => state.signIn;

export const selectIsSignInComplete = (state) =>
  selectSignIn(state).isSignInComplete;

/** Invoice */
export const selectInvoice = (state) => state.invoice;

export const selectInvoiceId = (state) => selectInvoice(state).InvoiceID;
export const selectPaymentCode = (state) => selectInvoice(state).PaymentCode;
export const selectInvoiceBalanceDue = (state) =>
  selectInvoice(state).BalanceDue;
export const selectPoNumber = (state) => selectInvoice(state).PONumber;

export const selectIsInvoicePayable = (state) => {
  const invoice = selectInvoice(state);

  return invoice.InvoiceStatusName !== status.PAID;
};

export const selectCreditCard = (state) => selectInvoice(state).creditCard;
export const selectCreditCardMaskedNumber = (state) =>
  selectCreditCard(state).MaskedNumber;
export const selectHasCreditCard = (state) =>
  Boolean(selectCreditCardMaskedNumber(state));

/** Payment */
export const selectPayment = (state) => state.payment;

export const selectPaymentError = (state) => selectPayment(state).paymentError;
export const selectPaymentType = (state) => selectPayment(state).paymentType;
export const selectPaymentMethod = (state) =>
  selectPayment(state).paymentMethod;
export const selectPaymentAmount = (state) =>
  selectPayment(state).paymentAmount;
export const selectPaymentAmountType = (state) =>
  selectPayment(state).paymentAmountType;
export const selectPaymentAmountError = (state) =>
  selectPayment(state).paymentAmountError;

export const selectHasPaymentAmountError = (state) =>
  Boolean(selectPayment(state).paymentAmountError);

export const selectIsInvoicePaid = (state) => {
  const payment = selectPayment(state);

  return Boolean(payment.invoicePaymentId);
};

export const selectPaymentEmail = (state) => selectPayment(state).email;
export const selectPaymentFullName = (state) => selectPayment(state).fullName;
export const selectPaymentEmailError = (state) =>
  selectPayment(state).emailError;
export const selectPaymentFullNameError = (state) =>
  selectPayment(state).fullNameError;

export const selectHasPaymentFormErrors = (state) =>
  hasErrors(selectPayment(state));

export const selectIsPosting = (state) => {
  const payment = selectPayment(state);
  return payment.isPosting || payment.isPostingCard;
};

/** Stripe */
export const selectStripe = (state) => state.stripe;
export const selectStripeClientSecret = (state) =>
  selectStripe(state).clientSecret;
export const selectStripePaymentIntentId = (state) =>
  selectStripe(state).paymentIntentId;
export const selectIsStripeLoaded = (state) =>
  Boolean(selectStripeClientSecret(state));
export const selectStripeCustomer = (state) => selectStripe(state).customer;

export const selectStripeCustomerBankAccount = (state) => {
  const accounts = selectStripeCustomer(state)?.BankAccounts;
  return accounts?.[0];
};

export const selectHasBankAccount = (state) =>
  Boolean(selectStripeCustomerBankAccount(state));

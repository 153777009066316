// External Dependencies
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const SectionTitle = styled(Typography)(({ theme }) => ({
  '&&': {
    fontFamily: theme.typography.circular,
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const FormSection = ({ children, title }) => {
  return (
    <StyledPaper>
      <SectionTitle component="div">{title}</SectionTitle>
      {children}
    </StyledPaper>
  );
};

export default FormSection;

import {
  calculatePaymentAmountInDollars,
  calculateRemainingBalance,
  getAdjustedInvoice,
} from '../../util/ach-helpers';
import { getInvoiceDataFromApi, putPoNumber } from '../../util/api';
import { navigateTo } from '../../util/navigation';
import { selectInvoiceId, selectPaymentCode } from '../selectors/selectors';
import { setPaymentAmount, updatePaymentAmount } from './payment-actions';
import { signInSuccess } from './signin-actions';
import actionTypes from '../action-types';
import paymentTypes from '../../constants/payment-type-constants';
import status from '../../constants/invoice-status';

export const updateInvoice = (invoice) => (dispatch) =>
  dispatch({
    type: actionTypes.GET_INVOICE_SUCCESS,
    invoice,
  });

export const getInvoiceSuccess = (invoice) => (dispatch) => {
  if (!invoice?.InvoiceStatusName) {
    // For now, only get the first error
    const defaultError = 'Invalid invoice number or payment code.';
    const apiError = invoice?.Status !== 403 ? invoice?.Detail : defaultError;
    const errorMessage = apiError || defaultError;
    throw new Error(errorMessage);
  } else if (!invoice?.creditCard?.AccountCreditId) {
    // No card on file, default payment type to New Card
    dispatch({
      type: actionTypes.UPDATE_PAYMENT_TYPE,
      paymentType: paymentTypes.NEW,
    });
  }

  const invoiceStatus = invoice?.InvoiceStatusName;
  if (
    invoiceStatus === status.AWAITING ||
    invoiceStatus === status.PARTIAL ||
    invoiceStatus === status.PAID
  ) {
    Promise.all([
      dispatch(updateInvoice(invoice)),
      dispatch(setPaymentAmount(invoice.BalanceDue)),
      dispatch(signInSuccess()),
    ]).then(navigateTo('/invoice'));
  } else {
    const errorMessage =
      'Invoice is processing. Please try again in a few minutes.';
    throw new Error(errorMessage);
  }
};

export const getInvoiceError = (error) => (dispatch) =>
  dispatch({
    type: actionTypes.GET_INVOICE_ERROR,
    error,
  });

export const getInvoiceById = (invoiceId, paymentCode) => (dispatch) => {
  // Call endpoint
  dispatch({
    type: actionTypes.GET_INVOICE_REQUEST,
  });

  return getInvoiceDataFromApi(invoiceId, paymentCode)
    .then(([invoice, creditCard]) => {
      const stateInvoice = {
        ...invoice,
        creditCard,
      };
      dispatch(getInvoiceSuccess(stateInvoice));
      return Promise.resolve(stateInvoice);
    })
    .catch((error) => {
      dispatch(getInvoiceError(error.message));
    });
};

export const openPoNumberEdit = () => (dispatch) => {
  dispatch({
    type: actionTypes.INVOICE_OPEN_EDIT,
  });
};

export const closePoNumberEdit = () => (dispatch) => {
  dispatch({
    type: actionTypes.INVOICE_CLOSE_EDIT,
  });
};

export const updatePoSuccess = (poNumber) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_PO_SUCCESS,
    poNumber,
  });
};

export const updatePoError = (error) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_PO_ERROR,
    error,
  });
};

export const updatePoNumber = (poNumber) => (dispatch, getState) => {
  const state = getState();
  const invoiceId = selectInvoiceId(state);
  const paymentCode = selectPaymentCode(state);

  dispatch({
    type: actionTypes.POST_PO_REQUEST,
  });

  return putPoNumber(invoiceId, paymentCode, poNumber)
    .then((updatedInvoice) => {
      if (updatedInvoice) {
        // Successfully updated poNumber, go ahead and update state tree
        dispatch(updatePoSuccess(poNumber));
        dispatch(closePoNumberEdit());
      } else {
        // Handle error
        const errorMessage = 'Error occurred';
        throw new Error(errorMessage);
      }
    })
    .catch((error) => {
      dispatch(updatePoError(error.message));
    });
};

export const getInvoiceAchReceipt =
  (invoiceId, paymentCode, paymentIntent) => (dispatch) => {
    dispatch({
      type: actionTypes.GET_INVOICE_REQUEST,
    });

    return getInvoiceDataFromApi(invoiceId, paymentCode)
      .then(([invoice, creditCard]) => {
        dispatch(signInSuccess());

        const adjustedInvoice = getAdjustedInvoice(
          invoice,
          paymentIntent,
          creditCard
        );

        dispatch(updateInvoice(adjustedInvoice));

        // The payment amount is displayed on the receipt
        const paymentAmount = calculatePaymentAmountInDollars(paymentIntent);
        dispatch(updatePaymentAmount(paymentAmount));

        const remainingBalance = calculateRemainingBalance(
          invoice,
          paymentIntent
        );

        // Update state with details about the payment
        dispatch({
          type: actionTypes.POST_INVOICE_PAYMENT_SUCCESS,
          invoicePaymentId: paymentIntent.id,
          checkNumber: '',
          ccDisplay: '',
          referenceCode: '',
          confirmationNumber: '',
          remainingBalance,
          paymentAmount,
        });
      })
      .catch((error) => {
        dispatch(getInvoiceError(error.message));
      });
  };

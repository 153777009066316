// External Dependencies
import styled from 'styled-components';

// Internal Dependencies
import PaymentAmountControl from './payment-methods/payment-amount-control';
import PaymentMethod from './payment-methods';

const StyledPaymentForm = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const PaymentForm = () => (
  <StyledPaymentForm>
    <PaymentAmountControl />
    <PaymentMethod />
  </StyledPaymentForm>
);

export default PaymentForm;

// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { selectCreditCardMaskedNumber } from '../../state/selectors/selectors';
import ExistingPaymentMethod from './existing-payment-method';
import paymentMethodTypes from '../../constants/payment-method-constants';

const ExistingCard = () => {
  const maskedNumber = useSelector(selectCreditCardMaskedNumber);

  const formattedDisplay = maskedNumber.replace('XXXXXXXXXXXX', '•••• ');

  return (
    <ExistingPaymentMethod
      number={formattedDisplay}
      type={paymentMethodTypes.CARD}
    />
  );
};

export default ExistingCard;

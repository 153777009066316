export const getPastPayments = (invoice, paymentIntent) => {
  // filter out the current payment from the list of payments
  const pastPayments = invoice.Payments.filter(
    (payment) => payment.PaymentIntentId !== paymentIntent.id
  );

  return pastPayments;
};

export const calculatePaymentAmountInDollars = (paymentIntent) => {
  // Stripe provides the amount as a number of cents.
  const paymentAmount = paymentIntent.amount / 100;

  return paymentAmount;
};

export const checkIsPaymentIncludedInTotal = (invoice, paymentIntent) => {
  // Check if the payments that have been processed for the invoice included
  // the payment intent id for the payment that was just submitted
  const paymentIntentId = paymentIntent.id;
  const isCurrentPaymentIncludedInTotal = invoice.Payments.some(
    (payment) => payment.PaymentIntentId === paymentIntentId
  );

  return isCurrentPaymentIncludedInTotal;
};

export const calculateRemainingBalance = (invoice, paymentIntent) => {
  // Calculate the total of all previous payments
  const currentPaymentAmount = calculatePaymentAmountInDollars(paymentIntent);

  // Check if the current payment is included in the total
  const isCurrentPaymentIncludedInTotal = checkIsPaymentIncludedInTotal(
    invoice,
    paymentIntent
  );

  // If the current payment is included in the total, the remaining balance
  // has already accounted for the payment that was just submitted.
  // If the current payment is not included in the total, the remaining balance
  // needs to be adjusted to include the payment that was just submitted.
  const remainingBalance = isCurrentPaymentIncludedInTotal
    ? invoice.BalanceDue
    : invoice.BalanceDue - currentPaymentAmount;

  return remainingBalance;
};

export const getAdjustedInvoice = (invoice, paymentIntent, creditCard) => {
  const pastPayments = getPastPayments(invoice, paymentIntent);

  return {
    ...invoice,
    creditCard,
    Payments: pastPayments,
  };
};

export const calculatePreviousPaymentsTotal = (invoice) => {
  // Calculate the total of all previous payments
  const totalPreviousPayments = invoice.Payments.reduce(
    (total, payment) => total + payment.Amount,
    0
  );

  return totalPreviousPayments;
};

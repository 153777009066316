// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

// Internal Dependencies
import { selectIsPosting } from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import { submitInvoicePayment } from '../../state/actions/payment-actions';

const propTypes = {
  paymentAmount: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  paymentAmount: '',
};

const ConfirmCardPaymentButton = ({ paymentAmount, onSubmit }) => {
  const dispatch = useDispatch();

  const isPosting = useSelector(selectIsPosting);
  const isPayButtonDisabled = isPosting;

  const handleSubmit = () => {
    dispatch(submitInvoicePayment()).then(() => onSubmit(false));
  };

  return (
    <Button
      color="secondary"
      disabled={isPayButtonDisabled}
      onClick={handleSubmit}
      {...setTestId('confirmPayInvoice')}
    >
      {isPosting ? (
        <CircularProgress color="inherit" size={25} />
      ) : (
        `Pay ${paymentAmount}`
      )}
    </Button>
  );
};

ConfirmCardPaymentButton.propTypes = propTypes;
ConfirmCardPaymentButton.defaultProps = defaultProps;

export default ConfirmCardPaymentButton;

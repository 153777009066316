// External Dependencies
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { calculatePreviousPaymentsTotal } from '../util/ach-helpers';
import { navigateSignin } from '../state/actions/signin-actions';
import { selectIsSignInComplete } from '../state/selectors/selectors';
import { setTestId } from '../util/testUtils';
import TEALogoSvg from './icons/tea-logo.svg';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'block',
  '.logo': {
    height: 50,
    width: 'auto',
    margin: '24px 0',
  },
  '.header': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  '.ceaAddress': {
    marginTop: -40,
    marginLeft: 80,
    fontSize: 12,
    color: grey['600'],
    fontWeight: 500,
  },
  '.invoiceDetails': {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '0',
  },
  '.paid': {
    display: 'flex',
    flexDirection: 'row',
    float: 'right',
    alignItems: 'flex-end',
    marginRight: 20,
    marginTop: -60,
  },
  '.paidStamp': {
    width: 200,
    height: 200,
    zIndex: 1000,
  },
  '.card': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  '.cardContent': {
    padding: theme.spacing(3),
    fontSize: 14,
  },
  '.paper': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.title': {
    marginTop: theme.spacing(8),
  },
  '.submit': {
    margin: theme.spacing(3, 0, 2),
  },
  '.grid': {
    textAlign: 'left',
  },
  '.divider': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  '.balance': {
    fontWeight: 'bold',
  },
  '.thank': {
    float: 'right',
    marginTop: 30,
  },
  '.thankYou': {
    width: 200,
  },
  '.button': {
    display: 'flex',
    justifyContent: 'space-around',
  },
  '.heading': {
    textAlign: 'left',
    color: 'white',
    fontWeight: 800,
    background: grey['600'],
    height: 40,
    padding: 10,
  },
  '.invoiceInformation, & .description': {
    padding: 10,
    fontSize: 14,
  },
  '.paidConfirmation': {
    color: green['600'],
    fontWeight: 600,
    lineHeight: 2,
    textAlign: 'right',
  },
  '.balanceDueStyles': {
    color: grey['800'],
    fontWeight: 800,
    fontSize: 16,
    lineHeight: 2,
    textAlign: 'right',
  },
  '.label': {
    color: grey['600'],
    fontWeight: 600,
    textAlign: 'right',
  },
  '.value': {
    textAlign: 'right',
  },
  '.tax': {
    marginRight: 20,
  },
  '.total': {
    marginRight: 20,
  },
  '@media print': {
    '.button': {
      display: 'none !important',
    },
  },
  '@media (max-width: 1024px)': {
    '.invoiceDetails': {
      width: 'max-content',
    },
    '.paid': {
      alignItems: 'baseline',
      marginTop: -10,
    },
    '.paidStamp': {
      width: 150,
      height: 150,
    },
    '.thank': {
      textAlign: 'center',
      float: 'none',
    },
    '.receiptItems': {
      margin: 5,
      padding: 0,
    },
    '.cardContent': {
      padding: 4,
    },
    '.grid': {
      padding: 4,
      margin: 4,
    },
  },
}));

const propTypes = {
  invoice: PropTypes.shape({
    accountId: PropTypes.number,
    amount: PropTypes.number,
    balanceDue: PropTypes.number,
    invoiceId: PropTypes.number,
    invoicePayments: PropTypes.array,
    lineItemDesc: PropTypes.string,
    poNumber: PropTypes.string,
    tax: PropTypes.number,
  }).isRequired,
  payment: PropTypes.shape({
    paymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    remainingBalance: PropTypes.number,
  }).isRequired,
  cardNumber: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  onNavigateSignin: PropTypes.func.isRequired,
};

const defaultProps = {
  cardNumber: '',
};

class PaymentSuccess extends React.Component {
  componentDidMount() {
    const { isAuthenticated, onNavigateSignin } = this.props;
    if (!isAuthenticated) {
      onNavigateSignin();
    }
  }

  getTotalPreviousPayments = () => {
    const { invoice } = this.props;
    const previousPaymentsTotal = calculatePreviousPaymentsTotal(invoice);

    // Don't display the previous payments total if it's 0
    return previousPaymentsTotal ? previousPaymentsTotal.toFixed(2) : '';
  };

  render() {
    const { invoice, payment, cardNumber, isAuthenticated, onNavigateSignin } =
      this.props;

    const { paymentAmount, remainingBalance } = payment;

    const { AccountID, InvoiceID, LineItemDesc, PONumber, Amount, Tax } =
      invoice;

    if (!isAuthenticated) {
      // Do not render until user authenticates
      // TODO: change to isPaymentSuccessful
      return null;
    }

    const amountDisplay = Amount.toFixed(2);
    const paymentAmountDisplay = parseFloat(paymentAmount).toFixed(2);
    const taxAmount = Tax?.Amount;
    const taxDisplay = taxAmount.toFixed(2);
    const paymentDate = moment().format('MMMM D, YYYY');
    const balanceDueDisplay = (
      Number(amountDisplay) + Number(taxDisplay)
    ).toFixed(2);
    const remainingBalanceDue = remainingBalance.toFixed(2);
    const previousPaymentsTotal = this.getTotalPreviousPayments(taxAmount);

    const paymentMethodDisplay = cardNumber
      ? `Paid with Card: ${cardNumber}`
      : 'Paid with ACH';

    return (
      <StyledContainer className="root" component="main" maxWidth="md">
        <Card className="card" elevation={2}>
          <CardContent className="cardContent">
            <div>
              <TEALogoSvg className="logo" />
            </div>
            <div className="ceaAddress">
              <p>
                3803 Parkwood Blvd, Suite 900
                <br />
                Frisco, TX 75034
                <br />
                877.226.3080
                <br />
                Email: AccountSupport@text-em-all.com
                <br />
                Website: www.text-em-all.com
                <br />
                Tax Id: 03-0565922
                <br />
              </p>
            </div>
            <div className="paid">
              {remainingBalance === 0 && (
                <img
                  alt="paid"
                  className="paidStamp"
                  id="paidStamp"
                  src="https://cdn.text-em-all.com/invoice-pdf/paid-stamp.gif"
                />
              )}
              <div className="invoiceDetails">
                <p>{paymentDate}</p>
                <p {...setTestId('invoiceId')}>
                  <b>Invoice #{InvoiceID}</b>
                </p>
                <p {...setTestId('accountId')}>
                  Account ID: &nbsp;
                  {AccountID}
                </p>
                <p>
                  PO Number: &nbsp;
                  {PONumber || 'N/A'}
                </p>
              </div>
            </div>
            <div className="receiptItems">
              <div className="description">
                <Grid className="heading" container>
                  <Grid item xs={10}>
                    Description
                  </Grid>
                  <Grid className="value" item xs={2}>
                    Amount
                  </Grid>
                </Grid>
                <Grid className="invoiceInformation" container>
                  <Grid item xs={9}>
                    {LineItemDesc}
                  </Grid>
                  <Grid className="value" item xs={3}>
                    $ &nbsp;
                    {amountDisplay}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="divider" />
                  </Grid>
                </Grid>
              </div>
              <div className="tax">
                <Grid className="grid" container>
                  <Grid className="label" item xs={9}>
                    Subtotal
                  </Grid>
                  <Grid className="value" item xs={3}>
                    $ {amountDisplay}
                  </Grid>
                  <Grid className="label" item xs={9}>
                    Tax
                  </Grid>
                  <Grid className="value" item xs={3}>
                    $ {taxDisplay}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="divider" />
                  </Grid>
                </Grid>
              </div>
              <div className="total">
                <Grid className="grid" container>
                  <Grid className="label" item xs={9}>
                    Grand Total
                  </Grid>
                  <Grid className="value" item xs={3}>
                    $ &nbsp;
                    {balanceDueDisplay}
                  </Grid>
                </Grid>
                {previousPaymentsTotal && (
                  <Grid className="paidConfirmation" container>
                    <Grid item xs={9}>
                      Previous Payments
                    </Grid>
                    <Grid item xs={3}>
                      ($ &nbsp;
                      {previousPaymentsTotal}
                      &nbsp;)
                    </Grid>
                  </Grid>
                )}
                <Grid className="paidConfirmation" container>
                  <Grid item xs={9}>
                    {`${paymentDate} - ${paymentMethodDisplay}`}
                  </Grid>
                  <Grid item xs={3} {...setTestId('paymentAmountDisplay')}>
                    ($ &nbsp;
                    {paymentAmountDisplay}
                    &nbsp;)
                  </Grid>
                </Grid>
                <Grid className="balanceDueStyles" container>
                  <Grid item xs={9}>
                    Balance Due
                  </Grid>
                  <Grid item xs={3} {...setTestId('balanceDue')}>
                    $ &nbsp;
                    {remainingBalanceDue}
                  </Grid>
                </Grid>
              </div>
              <div className="thank">
                <img
                  alt="thank-you"
                  className="thankYou"
                  src="https://cdn.text-em-all.com/invoice-pdf/ThankYou.gif"
                />
              </div>
              <br />
              <Grid className="button" container>
                <Button
                  className="submit"
                  color="secondary"
                  onClick={onNavigateSignin}
                  variant="contained"
                >
                  Pay another invoice
                </Button>
                <Button
                  className="submit"
                  color="secondary"
                  onClick={() => window.print()}
                  variant="contained"
                >
                  Print this receipt
                </Button>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </StyledContainer>
    );
  }
}

function mapStateToProps(state) {
  const { invoice, payment } = state;

  const { MaskedNumber: cardNumber } = payment;

  return {
    invoice,
    payment,
    cardNumber,
    isAuthenticated: selectIsSignInComplete(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onNavigateSignin: navigateSignin,
    },
    dispatch
  );
}

PaymentSuccess.propTypes = propTypes;
PaymentSuccess.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
);

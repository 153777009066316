const status = {
  AWAITING: 'AwaitingPayment', // Invoice Status ID 3
  PARTIAL: 'PartialPaymentReceived', // 17
  PAID: 'PaymentReceived', // 11
  VOIDED: 'Voided', // 7
  PROCESSING: 'Processing', // 0, 1, 2, 4, 5, 6, 8, 9, 10. 12, 13, 14, 15
  CANCELLED: 'Canceled', // 16
};

export default status;

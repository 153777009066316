// External Dependencies
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Internal Dependencies
import { initializeStripe } from '../../state/actions/stripe-actions';
import {
  selectInvoiceId,
  selectStripeClientSecret,
} from '../../state/selectors/selectors';

// Publishable key is not sensitive and can be exposed to the client
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const clientSecret = useSelector(selectStripeClientSecret);
  const invoiceId = useSelector(selectInvoiceId);

  useEffect(() => {
    if (STRIPE_PUBLISHABLE_KEY) {
      dispatch(initializeStripe());
    } else {
      console.error(
        'Missing Stripe Key. Please add REACT_APP_STRIPE_PUBLISHABLE_KEY to the environment variables.'
      );
    }

    // Re-initialize stripe instance when invoiceId changes
  }, [dispatch, invoiceId]);

  const options = {
    clientSecret,
  };

  return clientSecret ? (
    <Elements options={options} stripe={stripePromise}>
      {children}
    </Elements>
  ) : (
    children
  );
};

export default StripeProvider;

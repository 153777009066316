import { apiGet, apiPost, apiPut } from './fetch-utils';

export const getInvoiceFromApi = (invoiceId, paymentCode) => {
  return apiGet('/payment-portal/invoice', invoiceId, paymentCode);
};

export const getStripeCustomerFromApi = (invoiceId, paymentCode) => {
  return apiGet('/payment-portal/stripe-customer', invoiceId, paymentCode);
};

export const getCreditCardFromApi = (invoiceId, paymentCode) => {
  return apiGet('/payment-portal/credit-card', invoiceId, paymentCode);
};

export const putPoNumber = (invoiceId, paymentCode, poNumber) => {
  return apiPut(`/payment-portal/invoice/${invoiceId}/ponumber`, {
    poNumber,
    paymentCode,
  });
};

export const postInvoicePayment = (invoiceId, paymentCode, payment) => {
  return apiPost('/payment-portal/invoices/pay', {
    InvoiceID: invoiceId,
    PaymentCode: paymentCode,
    ...payment,
  });
};

export const getInvoiceDataFromApi = (invoiceId, paymentCode) => {
  return Promise.all([
    getInvoiceFromApi(invoiceId, paymentCode),
    getCreditCardFromApi(invoiceId, paymentCode),
  ]);
};

export const postCreditCard = (invoiceId, paymentCode, creditCard) => {
  return apiPost('/payment-portal/credit-card', {
    InvoiceID: invoiceId,
    PaymentCode: paymentCode,
    CreditCard: creditCard,
  });
};

export const postPaymentIntent = (invoiceId, paymentCode, amount) => {
  return apiPost('/payment-portal/stripe-payment', {
    InvoiceID: invoiceId,
    PaymentCode: paymentCode,
    Amount: amount,
  });
};

export const putPaymentIntentAmount = (
  invoiceId,
  paymentCode,
  paymentIntentId,
  amount
) => {
  return apiPut(`/payment-portal/payment-intent/${paymentIntentId}/amount`, {
    InvoiceID: invoiceId,
    PaymentCode: paymentCode,
    Amount: amount,
  });
};

// External Dependencies
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  saveCreditCard,
  update,
  updatePaymentType,
} from '../../state/actions/payment-actions';
import {
  selectHasCreditCard,
  selectPayment,
} from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import { StateOptions } from '../../constants/app-constants';
import paymentTypes from '../../constants/payment-type-constants';

const StyledRoot = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  '.newCardForm': {
    marginTop: theme.spacing(2),
  },
  '.cardActions': {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
  '.formLabel': {
    margin: theme.spacing(5, 0, 2),
  },
  '.subtitle': {
    margin: theme.spacing(3, 0, 2),
  },
  '.rowStyles': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  '.textFieldStyles, .inputStyle': {
    marginRight: '8px',
  },
  '.cancelButton': {
    marginRight: '8px',
  },
  '.slashStyles': {
    alignSelf: 'flex-start',
    color: theme.palette.text.secondary,
    flexShrink: 0,
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    width: '24px',
  },
}));

const NewCardForm = () => {
  const dispatch = useDispatch();
  const hasCreditCard = useSelector(selectHasCreditCard);
  const payment = useSelector(selectPayment, shallowEqual);
  const onUpdate = (payload) => dispatch(update(payload));

  const {
    billingAddress1,
    billingAddress1Error,
    billingAddress2,
    billingAddress2Error,
    billingCity,
    billingCityError,
    billingState,
    billingStateError,
    billingZipCode,
    billingZipCodeError,
    cardNumber,
    cardNumberError,
    ccExpirationMonth,
    ccExpirationMonthError,
    ccExpirationYear,
    ccExpirationYearError,
    cvn,
    cvnError,
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    saveCC,
  } = payment;

  const handleCancel = () => {
    dispatch(updatePaymentType(paymentTypes.EXISTING));
  };

  const handleSave = () => {
    dispatch(saveCreditCard());
  };

  return (
    <StyledRoot className="newCardForm">
      <FormLabel className="formLabel" component="legend">
        * Required information
      </FormLabel>
      <div className="rowStyles">
        <TextField
          {...setTestId('firstName')}
          autoFocus
          className="textFieldStyles"
          error={Boolean(firstNameError)}
          fullWidth
          helperText={firstNameError}
          label="First Name *"
          name="firstName"
          onChange={(e) => onUpdate({ firstName: e.target.value })}
          placeholder="James"
          value={firstName}
        />
        <TextField
          {...setTestId('lastName')}
          error={Boolean(lastNameError)}
          fullWidth
          helperText={lastNameError}
          label="Last Name *"
          name="lastName"
          onChange={(e) => onUpdate({ lastName: e.target.value })}
          placeholder="Smith"
          value={lastName}
        />
      </div>
      <div className="rowStyles">
        <TextField
          {...setTestId('cardNumber')}
          error={Boolean(cardNumberError)}
          fullWidth
          helperText={cardNumberError}
          label="Card Number *"
          name="cardNumber"
          onChange={(e) => onUpdate({ cardNumber: e.target.value })}
          placeholder="4444333322221111"
          value={cardNumber}
        />
      </div>
      <Grid container>
        <Grid className="rowStyles" item md={4} xs={5}>
          <TextField
            {...setTestId('expiryMonth')}
            error={Boolean(ccExpirationMonthError)}
            fullWidth
            helperText={ccExpirationMonthError}
            label="Exp Month *"
            name="ccExpirationMonth"
            onChange={(e) => onUpdate({ ccExpirationMonth: e.target.value })}
            placeholder="05 for May"
            value={ccExpirationMonth}
          />
        </Grid>
        <Grid className="slashStyles" item xs={1}>
          /
        </Grid>
        <Grid className="rowStyles" item md={4} xs={6}>
          <TextField
            {...setTestId('expiryYear')}
            className="textFieldStyles"
            error={Boolean(ccExpirationYearError)}
            fullWidth
            helperText={ccExpirationYearError}
            label="Exp Year *"
            name="ccExpirationYear"
            onChange={(e) => onUpdate({ ccExpirationYear: e.target.value })}
            placeholder="22 for 2022"
            value={ccExpirationYear}
          />
        </Grid>
        <Grid className="rowStyles" item md={3} xs={12}>
          <TextField
            {...setTestId('cvcNew')}
            className="inputStyle"
            error={Boolean(cvnError)}
            helperText={cvnError}
            label="CVN Code *"
            onChange={(e) => onUpdate({ cvn: e.target.value })}
            placeholder="123"
            value={cvn}
          />
        </Grid>
      </Grid>
      <Typography className="subtitle" variant="subtitle2">
        Billing Address
      </Typography>
      <div className="rowStyles">
        <TextField
          {...setTestId('billingAddress1')}
          error={Boolean(billingAddress1Error)}
          fullWidth
          helperText={billingAddress1Error}
          label="Billing Address 1 *"
          name="billingAddress1"
          onChange={(e) => onUpdate({ billingAddress1: e.target.value })}
          placeholder="123 Main Street"
          value={billingAddress1}
        />
      </div>
      <div className="rowStyles">
        <TextField
          {...setTestId('billingAddress2')}
          error={Boolean(billingAddress2Error)}
          fullWidth
          helperText={billingAddress2Error}
          label="Billing Address 2 (Optional)"
          name="billingAddress2"
          onChange={(e) => onUpdate({ billingAddress2: e.target.value })}
          placeholder="Apt 1234"
          value={billingAddress2}
        />
      </div>
      <Grid container spacing={1}>
        <Grid item md={4} xs={6}>
          <TextField
            {...setTestId('city')}
            error={Boolean(billingCityError)}
            fullWidth
            helperText={billingCityError}
            label="City *"
            name="billingCity"
            onChange={(e) => onUpdate({ billingCity: e.target.value })}
            placeholder="Dallas"
            value={billingCity}
          />
        </Grid>
        <Grid item md={5} xs={6}>
          <FormControl
            {...setTestId('billingStateControl')}
            className="formControl"
            error={Boolean(billingStateError)}
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="billing-state" required>
              State
            </InputLabel>
            <Select
              value={billingState}
              {...setTestId('billingState')}
              className="selectEmpty"
              label="State"
              native
              onChange={(e) => onUpdate({ billingState: e.target.value })}
              required
              variant="outlined"
            >
              {StateOptions.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.text}
                </option>
              ))}
            </Select>
            <FormHelperText>{billingStateError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            {...setTestId('zipCode')}
            error={Boolean(billingZipCodeError)}
            fullWidth
            helperText={billingZipCodeError}
            label="Zip Code *"
            name="billingZipCode"
            onChange={(e) => onUpdate({ billingZipCode: e.target.value })}
            placeholder="75095"
            value={billingZipCode}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                {...setTestId('saveCard')}
                color="primary"
                name="saveCard"
                onChange={() => onUpdate({ saveCC: !saveCC })}
                value={saveCC}
              />
            }
            label="Save card details to account"
          />
        </Grid>
      </Grid>
      {hasCreditCard && (
        <CardActions className="cardActions">
          <Button
            className="cancelButton"
            color="secondary"
            onClick={handleCancel}
            {...setTestId('cancel-new-card')}
          >
            Cancel
          </Button>
          <Button
            {...setTestId('save-new-card')}
            color="secondary"
            onClick={handleSave}
            variant="contained"
          >
            Update Card
          </Button>
        </CardActions>
      )}
    </StyledRoot>
  );
};

export default NewCardForm;

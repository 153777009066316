// External Dependencies
import { useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { API_URL } from '../../util/fetch-utils';
import {
  selectInvoiceId,
  selectPaymentCode,
} from '../../state/selectors/selectors';

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
}));

const DownloadInvoiceLink = () => {
  const invoiceId = useSelector(selectInvoiceId);
  const paymentCode = useSelector(selectPaymentCode);

  const downloadUrl = `${API_URL}/payment-portal/invoice/${invoiceId}.pdf?paymentCode=${paymentCode}`;

  return (
    <StyledLink download href={downloadUrl}>
      <Typography>Download</Typography>
      <DownloadIcon fontSize="small" />
    </StyledLink>
  );
};

export default DownloadInvoiceLink;

import { history } from './init';

export const navigateTo = (path, delay = 0) => {
  if (!delay) {
    history.push(path);
  } else {
    // In case there's an animation between transitions
    setTimeout(() => {
      history.push(path);
    }, delay);
  }
};

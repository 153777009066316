// External Dependencies
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  selectIsStripeLoaded,
  selectPaymentMethod,
  selectPaymentType,
} from '../../state/selectors/selectors';
import ExistingBankAccount from './existing-bank-account';
import ExistingCard from './existing-card';
import NewBankAccountForm from './new-bank-account-form';
import NewCardForm from './new-card-form';
import paymentMethodTypes from '../../constants/payment-method-constants';
import paymentTypes from '../../constants/payment-type-constants';

const PaymentMethodForm = () => {
  const paymentMethod = useSelector(selectPaymentMethod);
  const paymentType = useSelector(selectPaymentType);
  const isStripeLoaded = useSelector(selectIsStripeLoaded);

  const isCard = paymentMethod === paymentMethodTypes.CARD;
  const isExistingPaymentMethod = paymentType === paymentTypes.EXISTING;
  const isNewPaymentMethod = paymentType === paymentTypes.NEW;

  const isBankAccount = paymentMethod === paymentMethodTypes.BANK_ACCOUNT;

  return (
    <>
      <Collapse
        in={isCard && isExistingPaymentMethod}
        mountOnEnter
        unmountOnExit
      >
        <ExistingCard />
      </Collapse>
      <Collapse in={isCard && isNewPaymentMethod} mountOnEnter unmountOnExit>
        <NewCardForm />
      </Collapse>
      <Collapse
        in={!isStripeLoaded && isBankAccount}
        mountOnEnter
        unmountOnExit
      >
        <Typography color="error" mt={2}>
          This payment method is not available for this invoice. Please pay with
          a card instead.
        </Typography>
      </Collapse>
      <Collapse
        in={isStripeLoaded && isBankAccount && isExistingPaymentMethod}
        mountOnEnter
        unmountOnExit
      >
        <ExistingBankAccount />
      </Collapse>
      <Collapse
        in={isBankAccount && isNewPaymentMethod}
        mountOnEnter
        unmountOnExit
      >
        <NewBankAccountForm />
      </Collapse>
    </>
  );
};

export default PaymentMethodForm;

// External Dependencies
import configureMockStore from 'redux-mock-store';
import thunk from 'redux-thunk';

// TODO: change this to only add data-cy in a development environment
export const setTestId = (testId) => ({
  'data-cy': testId,
  name: testId,
  id: testId,
});

export const isCypressTest = () => Boolean(window.Cypress);

export const configureMockStoreWithMiddlewares = () => {
  const middlewares = [thunk];
  return configureMockStore(middlewares);
};

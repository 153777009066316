// External Dependencies
import { Route, Router, Switch } from 'react-router-dom';

// Internal Dependencies
import { history } from './util/init';
import AchSuccess from './components/stripe/ach-success';
import PaymentPortal from './components/PaymentPortal';
import PaymentSuccess from './components/PaymentSuccess';

export default function PortalRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route component={PaymentSuccess} exact path="/payment-success" />
        <Route component={AchSuccess} path="/ach-success" />
        <Route component={PaymentPortal} path="/" />
      </Switch>
    </Router>
  );
}

// External Dependencies
import { PaymentElement } from '@stripe/react-stripe-js';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import FormLabel from '@mui/material/FormLabel';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  selectHasBankAccount,
  selectPayment,
  selectStripeClientSecret,
} from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import { update, updatePaymentType } from '../../state/actions/payment-actions';
import paymentTypes from '../../constants/payment-type-constants';

const StyledRoot = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  '.formLabel': {
    margin: theme.spacing(5, 0, 2),
  },
  '.cardActions': {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
}));

const NewBankAccountForm = () => {
  const dispatch = useDispatch();
  const payment = useSelector(selectPayment, shallowEqual);
  const clientSecret = useSelector(selectStripeClientSecret);
  const hasBankAccount = useSelector(selectHasBankAccount);

  const paymentElementOptions = {
    layout: 'tabs',
    loader: 'auto',
    business: {
      name: 'Text-Em-All',
    },
    fields: {
      billingDetails: {
        email: 'never',
        name: 'never',
      },
    },
  };

  if (!clientSecret) {
    return (
      <Typography color="error" mt={2}>
        Missing Stripe Key. Please add REACT_APP_STRIPE_PUBLISHABLE_KEY to the
        environment variables.
      </Typography>
    );
  }

  const onUpdate = (payload) => dispatch(update(payload));

  const handleCancel = () => {
    dispatch(updatePaymentType(paymentTypes.EXISTING));
  };

  const { email, emailError, fullName, fullNameError } = payment;

  return (
    <StyledRoot>
      <FormLabel className="formLabel" component="legend" mt={2}>
        * Required information
      </FormLabel>
      <Box mb={2.5}>
        <TextField
          {...setTestId('email')}
          error={Boolean(emailError)}
          fullWidth
          helperText={emailError}
          InputLabelProps={{ shrink: true }}
          label="Email"
          name="email"
          onChange={(e) => onUpdate({ email: e.target.value })}
          required
          value={email}
        />
      </Box>
      <Box mb={1}>
        <TextField
          {...setTestId('fullName')}
          error={Boolean(fullNameError)}
          fullWidth
          helperText={fullNameError}
          InputLabelProps={{ shrink: true }}
          label="Full Name"
          name="fullName"
          onChange={(e) => onUpdate({ fullName: e.target.value })}
          placeholder="First and last name"
          required
          value={fullName}
        />
      </Box>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {hasBankAccount && (
        <CardActions className="cardActions">
          <Button
            className="cancelButton"
            color="secondary"
            onClick={handleCancel}
            {...setTestId('cancel-new-bank-account')}
          >
            Cancel
          </Button>
        </CardActions>
      )}
    </StyledRoot>
  );
};

export default NewBankAccountForm;

const getRoundedPaymentAmount = (paymentAmount, balanceDue) => {
  let paymentAmountNumber = parseFloat(paymentAmount);
  if (Number.isNaN(paymentAmountNumber)) {
    // If entered amount is not a number, set it to the balance due
    paymentAmountNumber = balanceDue;
  }
  paymentAmountNumber = paymentAmountNumber.toFixed(2);

  return paymentAmountNumber;
};

export const formatPaymentAmount = (paymentAmount, balanceDue) => {
  const roundedPaymentAmount = getRoundedPaymentAmount(
    paymentAmount,
    balanceDue
  );

  return `$${roundedPaymentAmount}`;
};

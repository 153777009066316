// External Dependencies
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import styled from 'styled-components';

// Internal Dependencies
import { navigateSignin } from '../state/actions/signin-actions';
import {
  selectIsInvoicePayable,
  selectIsSignInComplete,
} from '../state/selectors/selectors';
import Checkout from './checkout';
import Footer from './footer';
import Help from './help';
import SignIn from './SignIn';
import TEALogoSvg from './icons/tea-logo.svg';

const StyledContainer = styled(Container)(({ theme, $isAuthenticated }) => ({
  display: 'block',
  transition: 'all 150ms ease-in',
  position: 'relative',
  '.card': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    transition: 'all 200ms ease-in',
    maxHeight: $isAuthenticated ? 2000 : 650,
    backgroundColor: $isAuthenticated ? 'rgba(255, 255, 255, 0)' : '',
    boxShadow: $isAuthenticated ? 'none' : theme.shadows[2],
  },
  '.logo': {
    height: 50,
    width: 'auto',
    margin: theme.spacing(3, 0, 0),
    marginBottom: $isAuthenticated ? theme.spacing(7) : theme.spacing(3),
  },
  '.backButton': {
    position: 'absolute',
    top: 44,
    left: -80,
    color: theme.palette.action.active,
  },
  '.divider': {
    margin: theme.spacing(1, 0),
  },
  '.content': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const transitionStyle = { transitionDelay: '150ms' };

const PaymentPortal = () => {
  const dispatch = useDispatch();
  const isPayable = useSelector(selectIsInvoicePayable);
  const isAuthenticated = useSelector(selectIsSignInComplete);

  const onNavigateSignin = () => {
    dispatch(navigateSignin());
  };

  const invoiceSummaryWidth = isPayable ? 'sm' : 'xs';
  const maxWidth = isAuthenticated ? invoiceSummaryWidth : 'xs';

  return (
    <StyledContainer
      $isAuthenticated={isAuthenticated}
      $isPayable={isPayable}
      maxWidth={maxWidth}
    >
      <Fade in={isAuthenticated} style={transitionStyle}>
        <Button className="backButton" onClick={onNavigateSignin}>
          <KeyboardArrowLeftIcon fontSize="large" />
        </Button>
      </Fade>

      <Card className="card">
        <CardContent>
          <div className="content">
            <TEALogoSvg className="logo" />
          </div>

          <Switch>
            <Route component={Checkout} path="/invoice" />
            <Route component={SignIn} />
          </Switch>
        </CardContent>
        <Divider className="divider" />
        <Help />
      </Card>
      <Footer />
    </StyledContainer>
  );
};

export default PaymentPortal;

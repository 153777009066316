// External Dependencies
import HelpIcon from '@mui/icons-material/Help';
import Link from '@mui/material/Link';
import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const StyledHelp = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  padding: theme.spacing(2),
  marginLeft: theme.spacing(1),
  '.row': {
    padding: '2px 12px',
  },
  '.help': {
    color: theme.palette.text.secondary,
  },
  '.link': {
    paddingLeft: theme.spacing(1),
  },
}));

const Help = () => {
  return (
    <StyledHelp>
      <HelpIcon className="help" />
      <Typography className="row" color="textSecondary">
        <Link
          className="link"
          href="https://support.text-em-all.com/article/537-paying-invoices-via-payment-portal"
          target="_blank"
        >
          Need Help?
        </Link>
      </Typography>
    </StyledHelp>
  );
};

export default Help;

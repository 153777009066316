// External Dependencies
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Local Dependencies
import {
  closePoNumberEdit,
  openPoNumberEdit,
  updatePoNumber,
} from '../../state/actions/invoice-actions';
import { selectInvoice, selectPoNumber } from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import LinkButton from '../common/link-button';

const StyledRoot = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '.edit': {
    marginLeft: theme.spacing(1),
  },
  '.saveButton': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '.cancelButton': {
    marginRight: theme.spacing(1),
  },
}));

const inputProps = {
  maxLength: 25,
  style: {
    textAlign: 'right',
  },
};

const EditPaymentOrder = () => {
  const invoice = useSelector(selectInvoice, shallowEqual);
  const poNumber = useSelector(selectPoNumber);
  const dispatch = useDispatch();

  const { isEditing, isSaving, poError } = invoice;
  const [localPONumber, setLocalPONumber] = useState(poNumber);

  const toggleEdit = () => {
    if (isEditing) {
      dispatch(closePoNumberEdit());
    } else {
      dispatch(openPoNumberEdit());
    }
  };

  const handleUpdate = (e) => {
    setLocalPONumber(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(updatePoNumber(localPONumber));
  };

  const handleFormKeyDown = (event) => {
    // Handle Enter key like the submit button
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Collapse in={isEditing} mountOnEnter unmountOnExit>
        <Grid container mb={1} spacing={1}>
          <Grid item ml={1} xs={12}>
            <StyledRoot>
              <TextField
                {...setTestId('poNumber')}
                autoFocus
                error={Boolean(poError)}
                fullWidth
                helperText={poError}
                inputProps={inputProps}
                margin="dense"
                onChange={handleUpdate}
                onKeyDown={handleFormKeyDown}
                placeholder="(Optional)"
                size="small"
                value={localPONumber}
              />
            </StyledRoot>
          </Grid>
          <Grid item xs={12}>
            <StyledRoot>
              <Button
                className="cancelButton"
                color="secondary"
                onClick={toggleEdit}
                size="small"
              >
                Cancel
              </Button>
              <Button
                {...setTestId('savePoNumber')}
                className="saveButton"
                color="secondary"
                onClick={handleSubmit}
                size="small"
                variant="contained"
              >
                {isSaving ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Save'
                )}
              </Button>
            </StyledRoot>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={!isEditing}>
        <StyledRoot>
          <Typography align="right" fontWeight="bold" gutterBottom>
            {poNumber}
          </Typography>
          <span className="edit">
            <LinkButton {...setTestId('editPoNumber')} onClick={toggleEdit}>
              {poNumber ? 'edit' : '+ Add PO'}
            </LinkButton>
          </span>
        </StyledRoot>
      </Collapse>
    </>
  );
};

export default EditPaymentOrder;

import actionTypes from '../action-types';
import paymentAmountTypes from '../../constants/payment-amount-type-constants';
import paymentMethodsTypes from '../../constants/payment-method-constants';
import paymentTypes from '../../constants/payment-type-constants';

const initialState = {
  paymentMethod: paymentMethodsTypes.CARD,
  paymentType: paymentTypes.EXISTING,
  paymentAmountType: paymentAmountTypes.FULL,
  paymentAmount: 0.0,
  cardNumber: '',
  ccDisplay: '',
  ccExpirationMonth: '',
  ccExpirationYear: '',
  cvn: '',
  firstName: '',
  lastName: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  accountCreditId: null,
  saveCC: false,
  isPosting: false,
  isPostingCard: false,
  paymentError: null,
  cardError: null,

  // ach
  fullName: '',
  email: '',

  // to be moved to invoice
  invoicePaymentId: null,
  confirmationNumber: '',
  checkNumber: '',
  referenceCode: '',
  remainingBalance: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return initialState;
    case actionTypes.UPDATE_PAYMENT_TYPE:
      return {
        ...initialState,
        paymentAmountType: state.paymentAmountType,
        paymentAmount: state.paymentAmount,
        paymentMethod: state.paymentMethod,
        paymentType: action.paymentType,
      };
    case actionTypes.UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        paymentType: initialState.paymentType,
      };
    case actionTypes.UPDATE_PAYMENT_FORM:
      return {
        ...state,
        ...action.validatedForm,
      };
    case actionTypes.UPDATE_PAYMENT_AMOUNT_TYPE:
      return {
        ...state,
        paymentAmountType: action.paymentAmountType,
      };

    case actionTypes.UPDATE_PAYMENT_AMOUNT:
      return {
        ...state,
        ...action.validatedForm,
      };
    case actionTypes.POST_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        AccountCreditId: action.AccountCreditId,
        MaskedNumber: action.MaskedNumber,
        isPostingCard: false,
      };
    case actionTypes.POST_CREDIT_CARD_ERROR:
      return {
        ...state,
        cardError: action.error,
        isPostingCard: false,
      };
    case actionTypes.POST_CREDIT_CARD_REQUEST:
      return {
        ...state,
        isPostingCard: true,
      };
    case actionTypes.POST_INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        accountCreditId: action.accountCreditId,
        invoicePaymentId: action.invoicePaymentId,
        confirmationNumber: action.confirmationNumber,
        referenceCode: action.referenceCode,
        checkNumber: action.checkNumber,
        remainingBalance: action.remainingBalance,
        isPosting: false,
        isPostingCard: false,
      };
    case actionTypes.POST_INVOICE_PAYMENT_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case actionTypes.POST_INVOICE_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: action.error,
        isPosting: false,
      };
    case actionTypes.CLEAR_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: null,
      };
    default:
      return state;
  }
};

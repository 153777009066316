import {
  getInvoiceFromApi,
  postCreditCard,
  postInvoicePayment,
} from '../../util/api';
import { getMinimumpaymentAmount } from '../../util/settings';
import { hasErrors, removeErrorKeys, validate } from '../../util/validation';
import { history } from '../../util/init';
import {
  selectCreditCard,
  selectHasCreditCard,
  selectHasPaymentFormErrors,
  selectInvoiceBalanceDue,
  selectInvoiceId,
  selectPayment,
  selectPaymentAmountType,
  selectPaymentCode,
  selectPaymentMethod,
  selectPaymentType,
  selectStripeCustomerBankAccount,
} from '../selectors/selectors';
import actionTypes from '../action-types';
import paymentAmountTypes from '../../constants/payment-amount-type-constants';
import paymentMethodTypes from '../../constants/payment-method-constants';
import paymentTypes from '../../constants/payment-type-constants';

const ccExpirationMonthError = 'Enter a 2 digit month';
const ccExpirationYearError = 'Enter a 2 digit year';

const newCardValidationRules = {
  cardNumber: {
    required: true,
    minLength: 13,
    maxLength: 16,
    creditCardNumber: true,
  },
  ccExpirationMonth: {
    required: true,
    requiredMessage: ccExpirationMonthError,
    maxLength: 2,
    maxLengthMessage: ccExpirationMonthError,
    maxValue: 12,
    maxValueMessage: ccExpirationMonthError,
    minLength: 2,
    minLengthMessage: ccExpirationMonthError,
    minValue: 1,
    minValueMessage: ccExpirationMonthError,
    number: true,
    numberMessage: ccExpirationMonthError,
  },
  ccExpirationYear: {
    required: true,
    requiredMessage: ccExpirationYearError,
    number: true,
    numberMessage: ccExpirationYearError,
    year: true,
  },
  cvn: {
    required: true,
    maxLength: 4,
    minLength: 3,
    number: true,
    minLengthMessage: 'Only 3-4 digits',
    maxLengthMessage: 'Only 3-4 digits',
  },
  firstName: {
    required: true,
    name: true,
    maxLength: 50,
  },
  lastName: {
    required: true,
    name: true,
    maxLength: 50,
  },
  billingAddress1: {
    required: true,
  },
  billingAddress2: {
    required: false,
  },
  billingCity: {
    required: true,
  },
  billingState: {
    required: true,
  },
  billingZipCode: {
    required: true,
  },
};

const newBankAccountValidationRules = {
  fullName: {
    required: true,
    name: true,
    maxLength: 256,
  },
  email: {
    required: true,
    requiredMessage: 'Enter an Email',
    email: true,
    maxLength: 800,
  },
};

export const updatePaymentType = (paymentType) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PAYMENT_TYPE, paymentType });
};

export const updatePaymentMethodToCard = () => (dispatch, getState) => {
  const hasCard = selectHasCreditCard(getState());

  // If the customer has a card saved, set the payment method to that card
  const paymentMethodType = hasCard ? paymentTypes.EXISTING : paymentTypes.NEW;
  dispatch(updatePaymentType(paymentMethodType));
};

export const updatePaymentMethodToBankAccount = () => (dispatch, getState) => {
  const hasBankAccounts = selectStripeCustomerBankAccount(getState());

  // If the customer has a bank account saved, set the payment method to that account
  const paymentMethodType = hasBankAccounts
    ? paymentTypes.EXISTING
    : paymentTypes.NEW;
  dispatch(updatePaymentType(paymentMethodType));
};

export const updatePaymentMethod = (paymentMethod) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_PAYMENT_METHOD, paymentMethod });

  if (paymentMethod === paymentMethodTypes.BANK_ACCOUNT) {
    dispatch(updatePaymentMethodToBankAccount());
  } else {
    dispatch(updatePaymentMethodToCard());
  }
};

const getPaymentAmountValidationRule = (balanceDue) => {
  // Get minimum payment amount from configuration in environment variable
  // Allow the user to pay less than the minimum amount ONLY if the remaining balance is less than
  // the minimum payment
  const minimumPayment = Math.min(getMinimumpaymentAmount(), balanceDue);

  const balanceDisplay = balanceDue.toFixed(2);
  const minimumPaymentDisplay = minimumPayment.toFixed(2);
  return {
    paymentAmount: {
      required: true,
      currency: true,
      minValue: minimumPayment,
      minValueMessage: `Payment amount must be at least $${minimumPaymentDisplay}`,
      maxValue: balanceDue,
      maxValueMessage: `Payment amount must be less than or equal to $${balanceDisplay}`,
    },
  };
};

const getPaymentValidationRules = (
  paymentType,
  paymentMethod,
  paymentAmountType,
  balanceDue
) => {
  const newPaymentMethodValidationRules =
    paymentMethod === paymentMethodTypes.CARD
      ? newCardValidationRules
      : newBankAccountValidationRules;

  // Existing payment methods don't need to be validated
  const paymentMethodValidationRules =
    paymentType === paymentTypes.EXISTING
      ? {}
      : newPaymentMethodValidationRules;

  const amountValidation = getPaymentAmountValidationRule(balanceDue);

  // No need to validate "Full Amount"
  const amountValidationRules =
    paymentAmountType === paymentAmountTypes.PARTIAL ? amountValidation : {};

  // Combine the validation rules for method and amount
  return { ...paymentMethodValidationRules, ...amountValidationRules };
};

export const update = (changes) => (dispatch, getState) => {
  const state = getState();
  const paymentType = selectPaymentType(state);
  const paymentMethodType = selectPaymentMethod(state);
  const paymentAmountType = selectPaymentAmountType(state);

  const balanceDue = selectInvoiceBalanceDue(state);

  // Combine the validation rules for method and amount
  const formValidationRules = getPaymentValidationRules(
    paymentType,
    paymentMethodType,
    paymentAmountType,
    balanceDue
  );

  const validatedForm = validate(changes, formValidationRules);
  dispatch({ type: actionTypes.UPDATE_PAYMENT_FORM, validatedForm });

  dispatch({ type: actionTypes.RESET_STEP_PAYMENT });
};

export const validatePayment = () => (dispatch, getState) => {
  const state = getState();
  const payment = selectPayment(state);
  const balanceDue = selectInvoiceBalanceDue(state);
  const paymentType = selectPaymentType(state);
  const paymentMethod = selectPaymentMethod(state);
  const paymentAmountType = selectPaymentAmountType(state);

  const formValidationRules = getPaymentValidationRules(
    paymentType,
    paymentMethod,
    paymentAmountType,
    balanceDue
  );

  // Remove any errors from review before validating payment
  const paymentForm = removeErrorKeys(payment);

  const validatedForm = validate(paymentForm, formValidationRules);

  if (hasErrors(validatedForm)) {
    dispatch({ type: actionTypes.UPDATE_PAYMENT_FORM, validatedForm });
  }

  return Promise.resolve(validatedForm);
};

export const saveCreditCard = () => (dispatch, getState) => {
  const state = getState();
  const payment = selectPayment(state);
  const invoiceId = selectInvoiceId(state);
  const paymentCode = selectPaymentCode(state);
  const creditCard = selectCreditCard(state);

  const {
    billingAddress1,
    billingAddress2,
    billingState,
    billingCity,
    billingZipCode,
    firstName,
    lastName,
    cvn,
    ccExpirationMonth,
    ccExpirationYear,
    cardNumber,
    paymentType,
    saveCC,
  } = payment;

  return dispatch(validatePayment()).then((validatedForm) => {
    if (hasErrors(validatedForm)) {
      return Promise.resolve();
    } else if (paymentType === paymentTypes.EXISTING) {
      // Update payment state with cc info from the invoice
      return Promise.resolve(
        dispatch({
          type: actionTypes.POST_CREDIT_CARD_SUCCESS,
          AccountCreditId: creditCard.AccountCreditId,
          MaskedNumber: creditCard.MaskedNumber,
        })
      );
    }

    // New Card
    // Take last 2 digits of card year. 22 -> 22 and 2022 -> 22
    const expYear = ccExpirationYear.slice(-2);

    // Change year to 21${expYear} in about 81 years
    const formattedExpirationDate = `${ccExpirationMonth}/20${expYear}`;

    const creditCardInput = {
      invoiceId,
      paymentCode,
      billingAddress1,
      billingAddress2,
      billingState,
      billingCity,
      billingZipCode,
      firstName,
      lastName,
      cvn,
      expirationDate: formattedExpirationDate,
      cardNumber,
      saveCC,
    };

    dispatch({ type: actionTypes.POST_CREDIT_CARD_REQUEST });
    return postCreditCard(invoiceId, paymentCode, creditCardInput)
      .then((cardResponse) => {
        if (!cardResponse?.AccountCreditId) {
          // For now, only get the first error
          const errorMessage = cardResponse.Message;
          return Promise.reject({ message: errorMessage });
        }

        dispatch({
          type: actionTypes.POST_CREDIT_CARD_SUCCESS,
          AccountCreditId: cardResponse.AccountCreditId,
          MaskedNumber: cardResponse.NumberMasked,
        });
        dispatch(updatePaymentType(paymentTypes.EXISTING));
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.POST_CREDIT_CARD_ERROR,
          error: error.message,
        });
      });
  });
};

export const payInvoice = () => (dispatch, getState) => {
  const state = getState();
  const payment = selectPayment(state);
  const invoiceId = selectInvoiceId(state);
  const paymentCode = selectPaymentCode(state);

  const { paymentAmount, accountCreditId } = payment;

  const paymentInput = {
    amount: paymentAmount,
    PaidByCC: true,
    accountCreditId,
    PortalPayment: true,
  };

  if (accountCreditId === null) {
    delete paymentInput.accountCreditId;
  }

  dispatch({ type: actionTypes.POST_INVOICE_PAYMENT_REQUEST });
  return postInvoicePayment(invoiceId, paymentCode, paymentInput)
    .then((res) => {
      if (res?.ErrorCode) {
        const errorMessage = res.Message;
        dispatch({
          type: actionTypes.POST_INVOICE_PAYMENT_ERROR,
          error: errorMessage,
        });

        return Promise.resolve();
      }

      if (res?.InvoicePaymentID) {
        const {
          InvoicePaymentID,
          ConfirmationNumber,
          CheckNumber,
          CCDisplay,
          ReferenceCode,
        } = res;

        // Get the new updated balance from the server to display on the receipt
        return getInvoiceFromApi(invoiceId, paymentCode).then((response) => {
          if (!response || response.Message) {
            const errorMessage = response
              ? response.Message
              : 'Invalid invoice number or payment code.';
            throw new Error(errorMessage);
          }

          // response is [invoice, creditCard]
          const invoice = response;
          // Retrieved new balance successfully
          const remainingBalance = invoice.BalanceDue;

          // Now we can display the receipt
          return Promise.resolve(
            dispatch({
              type: actionTypes.POST_INVOICE_PAYMENT_SUCCESS,
              invoicePaymentId: InvoicePaymentID,
              checkNumber: CheckNumber,
              ccDisplay: CCDisplay,
              referenceCode: ReferenceCode,
              confirmationNumber: ConfirmationNumber,
              remainingBalance,
            })
          ).then(() => history.push('/payment-success'));
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.POST_INVOICE_PAYMENT_ERROR,
        error: error.message,
      });
    });
};

export const submitInvoicePayment = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.CLEAR_PAYMENT_ERROR });

  return dispatch(saveCreditCard()).then(() => {
    const state = getState();
    const hasFormErrors = selectHasPaymentFormErrors(state);
    if (!hasFormErrors) {
      return dispatch(payInvoice());
    }
    return Promise.resolve();
  });
};

export const updatePaymentAmount = (paymentAmount) => (dispatch, getState) => {
  const balanceDue = selectInvoiceBalanceDue(getState());

  const amountValidation = getPaymentAmountValidationRule(
    Number(balanceDue.toFixed(2))
  );

  const validatedForm = validate({ paymentAmount }, amountValidation);

  return dispatch({ type: actionTypes.UPDATE_PAYMENT_AMOUNT, validatedForm });
};

export const setPaymentAmount = (paymentAmount) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_PAYMENT_AMOUNT,
    validatedForm: { paymentAmount },
  });
};

export const updatePaymentAmountType =
  (paymentAmountType) => (dispatch, getState) => {
    const balanceDue = selectInvoiceBalanceDue(getState());
    dispatch({
      type: actionTypes.UPDATE_PAYMENT_AMOUNT_TYPE,
      paymentAmountType,
    });

    // Set the payment amount to the full balance and re-run validation to clear any error
    dispatch(updatePaymentAmount(balanceDue.toFixed(2)));
  };

// Initial button click for Pay. This triggers validation and the dialog.
// It does not trigger the actual payment
export const submitInitialPay = (setIsDialogOpen) => (dispatch) => {
  return dispatch(validatePayment()).then((validatedForm) => {
    if (hasErrors(validatedForm)) {
      return Promise.resolve();
    }
    setIsDialogOpen(true);
  });
};

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getQueryParam } from '../util/queryParamUtils';
import {
  resetApp,
  signInSubmit,
  update,
} from '../state/actions/signin-actions';
import { setTestId } from '../util/testUtils';

const StyledPaper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.instructions': {
    width: '100%',
  },
  '.submit': {
    margin: theme.spacing(3, 0, 2),
    width: 200,
  },
  '.grid': {
    textAlign: 'left',
  },
}));

const transitionDelay = {
  transitionDelay: '100ms',
};

const noDelay = {
  transitionDelay: '0ms',
};

class SignIn extends React.Component {
  componentDidMount = () => {
    const { handleReset } = this.props;

    // Reset the form when Sign In mounts.
    // This is necessary when the user navigates back to the Sign In page with
    // the browser's back button
    handleReset();

    // Check if there are query any params
    if (window.location.search) {
      // Get invoiceId and paymentCode from the URL query params
      // ?invoiceNumber=1234&paymentCode=ABC123
      const invoiceId = getQueryParam('invoiceNumber');
      const paymentCode = getQueryParam('paymentCode');

      // Both are required
      if (invoiceId && paymentCode) {
        const { handleSignInSubmit, onUpdate } = this.props;

        // Populate text fields and submit
        Promise.resolve()
          .then(() =>
            onUpdate({
              invoiceId,
              paymentCode,
            })
          )
          .then(() => handleSignInSubmit(invoiceId, paymentCode));
      }
    }
  };

  handleSubmit = () => {
    const { handleSignInSubmit, invoiceId, paymentCode, isGetting } =
      this.props;

    // 2211, 0E4419
    // 2214, E025D7

    // 2338, 8A4CE0 (no PO number)

    // 2214, E025D7 (no cc on file, no partial payments)
    // 2281, 4E2EBA (cc on file, no partial payments)
    // 2293, BF1687 (cc on file and some partial payments)

    if (!isGetting) {
      handleSignInSubmit(invoiceId, paymentCode);
    }
  };

  handleFormKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  render() {
    const {
      error,
      invoiceId,
      invoiceIdError,
      paymentCode,
      paymentCodeError,
      isGetting,
      onUpdate,
      firstLoad,
    } = this.props;

    return (
      <Fade in style={firstLoad ? noDelay : transitionDelay}>
        <StyledPaper>
          <Typography
            align="center"
            className="instructions"
            gutterBottom
            variant="subtitle1"
          >
            Enter your invoice number and payment code below to make a payment.
          </Typography>
          <TextField
            {...setTestId('invoiceId')}
            error={Boolean(invoiceIdError)}
            fullWidth
            helperText={invoiceIdError}
            label="Invoice Number"
            margin="normal"
            onChange={(e) => onUpdate({ invoiceId: e.target.value.trim() })}
            onKeyDown={this.handleFormKeyDown}
            placeholder="Enter invoice number from the invoice"
            required
            value={invoiceId}
            variant="outlined"
          />
          <TextField
            {...setTestId('paymentCode')}
            error={Boolean(paymentCodeError)}
            fullWidth
            helperText={paymentCodeError}
            label="Payment Code"
            margin="normal"
            onChange={(e) =>
              onUpdate({ paymentCode: e.target.value.toUpperCase().trim() })
            }
            onKeyDown={this.handleFormKeyDown}
            placeholder="Enter payment code from the invoice"
            required
            value={paymentCode}
            variant="outlined"
          />
          <Button
            {...setTestId('submit')}
            className="submit"
            color="secondary"
            disabled={isGetting}
            onClick={this.handleSubmit}
            variant="contained"
          >
            {isGetting ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Submit'
            )}
          </Button>
          {error && (
            <Typography
              {...setTestId('signinError')}
              align="center"
              className="instructions"
              color="error"
            >
              {error}
            </Typography>
          )}
        </StyledPaper>
      </Fade>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceId: state.signIn.invoiceId,
    invoiceIdError: state.signIn.invoiceIdError,
    paymentCode: state.signIn.paymentCode,
    paymentCodeError: state.signIn.paymentCodeError,
    firstLoad: state.signIn.firstLoad,
    error: state.invoice.error,
    isGetting: state.invoice.isGetting,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onUpdate: update,
      handleSignInSubmit: signInSubmit,
      handleReset: resetApp,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

// External Dependencies
import { shallowEqual, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import { selectPayment } from '../../state/selectors/selectors';
import FormSection from '../common/form-section';
import PaymentMethodControl from './payment-method-control';
import PaymentMethodForm from './payment-method-form';

const PaymentMethods = () => {
  const payment = useSelector(selectPayment, shallowEqual);

  const { cardError } = payment;

  return (
    <FormSection title="How would you like to pay?">
      <PaymentMethodControl />
      <PaymentMethodForm />
      {cardError && (
        <Typography align="center" color="error">
          We were unable to process your credit card. Please contact the issuing
          bank for assistance.
        </Typography>
      )}
    </FormSection>
  );
};

export default PaymentMethods;

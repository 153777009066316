// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// Local Dependencies
import { formatPaymentAmount } from '../../util/currency';
import { navigateSignin } from '../../state/actions/signin-actions';
import {
  selectHasPaymentAmountError,
  selectInvoiceBalanceDue,
  selectInvoiceId,
  selectIsInvoicePayable,
  selectIsPosting,
  selectIsStripeLoaded,
  selectPaymentAmount,
  selectPaymentError,
  selectPaymentMethod,
} from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import { submitInitialPay } from '../../state/actions/payment-actions';
import ConfirmPaymentDialog from '../payment-methods/confirm-payment-dialog';
import DownloadInvoiceLink from './download-invoice-link';
import EditPaymentOrder from './edit-po-control';
import FormSection from '../common/form-section';
import paymentMethodTypes from '../../constants/payment-method-constants';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '.paper': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '.header': {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  '.payContainer': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  '.legal': {
    textAlign: 'center',
    maxWidth: 310,
  },
  '.legalWrapper': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const InvoiceSummary = () => {
  const dispatch = useDispatch();
  const isPosting = useSelector(selectIsPosting);
  const isPayable = useSelector(selectIsInvoicePayable);
  const paymentAmount = useSelector(selectPaymentAmount);
  const paymentError = useSelector(selectPaymentError);
  const paymentMethod = useSelector(selectPaymentMethod);
  const balanceDue = useSelector(selectInvoiceBalanceDue);
  const hasPaymentAmountError = useSelector(selectHasPaymentAmountError);
  const invoiceId = useSelector(selectInvoiceId);
  const isStripeLoaded = useSelector(selectIsStripeLoaded);
  const isBankAccount = paymentMethod === paymentMethodTypes.BANK_ACCOUNT;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Do not attempt to render until invoice is loaded
  if (!invoiceId) {
    return null;
  }

  const handleSubmit = () => {
    if (isPayable) {
      dispatch(submitInitialPay(setIsDialogOpen));
    } else {
      dispatch(navigateSignin());
    }
  };

  const formattedPaymentAmount = formatPaymentAmount(paymentAmount, balanceDue);

  const buttonLabel = isPayable
    ? `Pay ${formattedPaymentAmount}`
    : 'Pay Another Invoice';

  const amountLabel = isPayable ? 'Payment Amount' : 'Amount Paid';
  const amountFontSize = isPayable ? '24px' : '16px';

  const isInvalidPaymentMethod = isBankAccount && !isStripeLoaded;
  const isPayButtonDisabled =
    hasPaymentAmountError || isPosting || isInvalidPaymentMethod;

  const title = (
    <Box>
      Invoice Summary
      <DownloadInvoiceLink />
    </Box>
  );

  const paymentMethodText =
    paymentMethod === paymentMethodTypes.CARD ? 'card' : 'bank account';

  return (
    <FormSection title={title}>
      <StyledGrid container>
        <Grid item xs={6}>
          <Typography gutterBottom>Invoice</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right" gutterBottom>
            {invoiceId}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography gutterBottom>PO Number</Typography>
        </Grid>
        <Grid item xs={7}>
          <EditPaymentOrder />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={amountFontSize} fontWeight="bold" gutterBottom>
            {amountLabel}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            align="right"
            fontSize={amountFontSize}
            fontWeight="bold"
            gutterBottom
          >
            {formattedPaymentAmount}
          </Typography>
        </Grid>

        <Grid
          alignItems="center"
          className="payContainer"
          item
          marginX={4}
          mt={3}
          xs={12}
        >
          <Button
            {...setTestId('payInvoice')}
            color="secondary"
            disabled={isPayButtonDisabled}
            fullWidth
            onClick={handleSubmit}
            size="large"
            variant="contained"
          >
            {isPosting ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              buttonLabel
            )}
          </Button>
        </Grid>
        {isPayable && (
          <Grid className="legalWrapper" item xs={12}>
            <Typography
              className="legal"
              color="textSecondary"
              fontSize={12}
              mb={2}
            >
              By clicking &quot;Pay,&quot; you are authorizing Text-Em-All to
              charge this {paymentMethodText}. This action cannot be undone.
            </Typography>
          </Grid>
        )}

        {paymentError && (
          <Grid item xs={12}>
            <Typography align="center" color="error">
              {paymentError}
            </Typography>
          </Grid>
        )}
      </StyledGrid>
      <ConfirmPaymentDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </FormSection>
  );
};

export default InvoiceSummary;

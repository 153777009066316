// External Dependencies
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { getMinimumpaymentAmount } from '../../util/settings';
import {
  updatePaymentAmount as onUpdatePaymentAmount,
  updatePaymentAmountType as onUpdatePaymentAmountType,
} from '../../state/actions/payment-actions';
import {
  selectInvoiceBalanceDue,
  selectPaymentAmount,
  selectPaymentAmountError,
  selectPaymentAmountType,
} from '../../state/selectors/selectors';
import { setTestId } from '../../util/testUtils';
import FormSection from '../common/form-section';
import paymentAmountTypes from '../../constants/payment-amount-type-constants';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '.inputCell': {
    marginLeft: theme.spacing(4),
  },
  '.customAmount': {
    marginTop: theme.spacing(1),
  },
  '.numberInput': {
    marginBottom: theme.spacing(1),
    width: '100%', // Fix IE display
  },
  '.amountInput': {
    textAlign: 'right',
  },
}));

const PaymentAmountControl = () => {
  const dispatch = useDispatch();
  const balanceDue = useSelector(selectInvoiceBalanceDue);
  const paymentAmountType = useSelector(selectPaymentAmountType);
  const paymentAmount = useSelector(selectPaymentAmount);
  const paymentAmountError = useSelector(selectPaymentAmountError);

  const handlePaymentAmountType = (event) => {
    dispatch(onUpdatePaymentAmountType(event.target.value));
  };

  const handleUpdateAmount = (event) => {
    dispatch(onUpdatePaymentAmount(event.target.value));
  };

  const roundedBal = balanceDue.toFixed(2);
  const showPartialAmount = roundedBal > getMinimumpaymentAmount();

  return (
    <FormSection title="How much would you like to pay?">
      <StyledGrid container>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              name="paymentMethod"
              onChange={handlePaymentAmountType}
              value={paymentAmountType}
            >
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    {...setTestId('paymentAmountTypeFull')}
                  />
                }
                label={`$${roundedBal} - Current Balance`}
                value={paymentAmountTypes.FULL}
              />
              {showPartialAmount && (
                <FormControlLabel
                  className="customAmount"
                  control={
                    <Radio
                      color="primary"
                      {...setTestId('paymentAmountTypePartial')}
                    />
                  }
                  label="Custom amount"
                  value={paymentAmountTypes.PARTIAL}
                />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid className="inputCell" item xs={4}>
          <Collapse in={paymentAmountType === paymentAmountTypes.PARTIAL}>
            <TextField
              autoFocus
              {...setTestId('paymentAmount')}
              className="numberInput"
              error={Boolean(paymentAmountError)}
              helperText={paymentAmountError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { className: 'amountInput' },
              }}
              label="Amount"
              name="amount"
              onChange={handleUpdateAmount}
              placeholder={roundedBal}
              type="number"
              value={paymentAmount}
            />
          </Collapse>
        </Grid>
      </StyledGrid>
    </FormSection>
  );
};

export default PaymentAmountControl;

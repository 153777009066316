import actionTypes from '../action-types';

// TODO: do not commit this -- testing only
const initialState = {
  invoiceId: '',
  invoiceIdError: null,
  paymentCode: '',
  paymentCodeError: null,
  firstLoad: true,
  isSignInComplete: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return {
        ...initialState,
      };
    case actionTypes.UPDATE_SIGN_IN:
      return {
        ...state,
        ...action.validatedForm,
      };
    case actionTypes.RESET_STEP_SIGN_IN:
      return {
        ...state,
        isSignInComplete: false,
      };
    default:
      return state;
  }
};

/* eslint-disable import/first */
import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import StylesProvider from '@mui/styles/StylesProvider';

import { initializeStore } from './state/reducers/root-reducer';
import { isCypressTest } from './util/testUtils';
import PortalRouter from './router';

// Create the redux store
// Entry point for initial state when Cypress testing
const initialState = isCypressTest() ? window.initialState : {};
const store = initializeStore(initialState);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// TEA orange branding
const deepOrange700 = deepOrange[700];

const theme = createTheme({
  palette: {
    secondary: {
      main: deepOrange700,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
});

root.render(
  <Provider store={store}>
    <StylesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PortalRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </Provider>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

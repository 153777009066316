import { deepOrange } from '@mui/material/colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Local Variables
const deepOrange700 = deepOrange['700'];

const StyledLinkButton = styled.button(({ theme }) => ({
  backgroundColor: 'inherit',
  border: 'none',
  borderRadius: 0,
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  lineHeight: '24px',
  padding: 0,
  '&:hover': {
    color: deepOrange700,
  },
}));

const propTypes = {
  children: PropTypes.node.isRequired,
};

const LinkButton = ({ children, ...other }) => (
  <StyledLinkButton type="button" {...other}>
    {children}
  </StyledLinkButton>
);

LinkButton.propTypes = propTypes;

export default LinkButton;

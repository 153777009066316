import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import invoice from './invoice-reducer';
import payment from './payment-reducer';
import signIn from './signin-reducer';
import stripe from './stripe-reducer';

export const rootReducer = combineReducers({
  signIn,
  invoice,
  payment,
  stripe,
});

// Include redux dev tools in the store enhancer if on development
const initialStoreEnhancer = () => {
  // Render redux dev tools if on development
  const composeEnhancers = composeWithDevTools({
    name: 'Text-Em-All Payment Portal',
    shouldCatchErrors: true,
    trace: process.env.REACT_APP_ENABLE_REDUX_TOOLS_TRACE,
  });

  const storeEnhancer =
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(
          applyMiddleware(
            ReduxThunk
            // routerMiddleware(),
          )
        )
      : applyMiddleware(
          ReduxThunk
          // routerMiddleware(),
        );

  return storeEnhancer;
};

// Creates a redux store
export const initializeStore = (initialState) =>
  createStore(rootReducer, initialState, initialStoreEnhancer());

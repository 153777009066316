// External Dependencies
import Link from '@mui/material/Link';
import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const StyledFooter = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'flex-end',
  width: '100%',
  '.row': {
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 12px',
    fontSize: 11,
    color: theme.palette.text.secondary,
  },
  '.link': {
    color: theme.palette.text.secondary,
  },
}));

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      <Typography className="row">
        {`Copyright © 2005-${year} Call-Em-All, LLC. All Rights Reserved.`}
      </Typography>
      <Typography className="row">
        <Link
          className="link"
          href="https://www.call-em-all.com/legal/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </Link>
        &nbsp; | &nbsp;
        <Link
          className="link"
          href="https://www.call-em-all.com/legal/terms-of-use"
          target="_blank"
        >
          Terms Of Use
        </Link>
        &nbsp; | &nbsp;
        <Link
          className="link"
          href="https://www.call-em-all.com/legal/responsible-use"
          target="_blank"
        >
          Responsible Use Policy
        </Link>
      </Typography>
    </StyledFooter>
  );
};

export default Footer;

export const getQueryParams = () => {
  const { search } = window.location;
  if (search) {
    const queryParams = search.split('&');
    return queryParams;
  }

  return null;
};

export const getQueryParam = (name) => {
  // Array of ['param1=value', 'param2=value']
  const queryParams = getQueryParams();
  if (queryParams) {
    // 'queryParam='
    const searchString = `${name}=`;

    // 'queryParm=value'
    const paramString = queryParams.find((p) => p.includes(searchString)) || '';

    // ['queryParm', 'value']
    const nameValuePair = paramString.split('=');

    // There should be only one equal sign and the value should be at index 1
    if (nameValuePair.length === 2 && nameValuePair[1]) {
      return nameValuePair[1];
    }
  }

  // Value not found
  return null;
};

import actionTypes from '../action-types';

const initialState = {
  clientSecret: null,
  paymentIntentId: null,
  customer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_STRIPE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        clientSecret: action.clientSecret,
        paymentIntentId: action.paymentIntentId,
      };
    case actionTypes.GET_STRIPE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.customer,
      };
    default:
      return state;
  }
};

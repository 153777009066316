// Internal Dependencies
import {
  getStripeCustomerFromApi,
  postPaymentIntent,
  putPaymentIntentAmount,
} from '../../util/api';
import {
  selectInvoiceBalanceDue,
  selectInvoiceId,
  selectPaymentAmount,
  selectPaymentCode,
  selectPaymentEmail,
  selectPaymentFullName,
  selectPaymentType,
  selectStripeClientSecret,
  selectStripeCustomerBankAccount,
  selectStripePaymentIntentId,
} from '../selectors/selectors';
import actionTypes from '../action-types';
import paymentTypes from '../../constants/payment-type-constants';

export const updateStripeData =
  (clientSecret, paymentIntentId) => (dispatch) => {
    dispatch({
      type: actionTypes.POST_STRIPE_PAYMENT_INTENT_SUCCESS,
      clientSecret,
      paymentIntentId,
    });
  };

export const initializePaymentIntent =
  (invoiceId, paymentCode) => (dispatch, getState) => {
    const balanceDue = selectInvoiceBalanceDue(getState());
    return postPaymentIntent(invoiceId, paymentCode, balanceDue).then(
      (response) => {
        const clientSecret = response.PaymentIntentClientSecret;
        const paymentIntentId = response.PaymentIntentId;
        dispatch(updateStripeData(clientSecret, paymentIntentId));
      }
    );
  };

export const getStripeCustomer = (invoiceId, paymentCode) => (dispatch) => {
  return getStripeCustomerFromApi(invoiceId, paymentCode).then((response) => {
    dispatch({
      type: actionTypes.GET_STRIPE_CUSTOMER_SUCCESS,
      customer: response,
    });
  });
};

export const initializeStripe = () => (dispatch, getState) => {
  const state = getState();
  const invoiceId = selectInvoiceId(state);
  const paymentCode = selectPaymentCode(state);

  return Promise.all([
    dispatch(initializePaymentIntent(invoiceId, paymentCode)),
    dispatch(getStripeCustomer(invoiceId, paymentCode)),
  ]);
};

export const updatePaymentIntentAmount = () => (dispatch, getState) => {
  const state = getState();
  const invoiceId = selectInvoiceId(state);
  const paymentCode = selectPaymentCode(state);
  const paymentIntentId = selectStripePaymentIntentId(state);
  const amount = selectPaymentAmount(state);

  // Update payment intent with the payment amount specified by the user.
  // This allows us to support partial payments for ACH.
  return putPaymentIntentAmount(
    invoiceId,
    paymentCode,
    paymentIntentId,
    amount
  ).then((res) => {
    if (!res.ok) {
      return Promise.reject('Failed to update payment intent amount.');
    }
    dispatch({
      type: actionTypes.PUT_PAYMENT_INTENT_AMOUNT_SUCCESS,
    });

    return Promise.resolve();
  });
};

export const stripePaymentSuccess = (response) => (dispatch) => {
  if (response.error) {
    return Promise.reject(response.error.message);
  }
  dispatch({ type: actionTypes.POST_INVOICE_PAYMENT_SUCCESS });
  return Promise.resolve(response);
};

export const submitStripePayment =
  (stripe, elements) => (dispatch, getState) => {
    const state = getState();
    const paymentType = selectPaymentType(state);
    const invoiceId = selectInvoiceId(state);
    const paymentCode = selectPaymentCode(state);
    const isExistingPaymentMethod = paymentType === paymentTypes.EXISTING;

    const { origin } = window.location;
    const successUrl = `${origin}/ach-success?invoiceid=${invoiceId}&paymentcode=${paymentCode}`;

    dispatch({ type: actionTypes.POST_INVOICE_PAYMENT_REQUEST });

    return dispatch(updatePaymentIntentAmount())
      .then(() => {
        if (isExistingPaymentMethod) {
          // Use saved bank account
          const clientSecret = selectStripeClientSecret(state);
          const bankAccount = selectStripeCustomerBankAccount(state);
          return stripe
            .confirmPayment({
              clientSecret,
              confirmParams: {
                payment_method: bankAccount.PaymentMethodId,
                return_url: successUrl,
              },
            })
            .then((res) => dispatch(stripePaymentSuccess(res)));
        }

        const email = selectPaymentEmail(state);
        const fullName = selectPaymentFullName(state);

        // Use newly entered bank account
        return stripe
          .confirmPayment({
            elements,
            confirmParams: {
              return_url: successUrl,
              payment_method_data: {
                billing_details: {
                  name: fullName,
                  email,
                },
              },
            },
          })
          .then((res) => dispatch(stripePaymentSuccess(res)));
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.POST_INVOICE_PAYMENT_ERROR,
          error,
        });
      });
  };
